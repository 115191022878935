import React, { LegacyRef } from "react";

import dynamic from "next/dynamic";
const IntroPicContainer = dynamic(() => import("../introPicContainer"));

import Startseite from "@images/Startseite.webp";

function Intro() {
  return (
    <IntroPicContainer
      imageSrc={Startseite}
      title={"Professionelle Reinigung in Hamburg"}
    />
  );
}

export default Intro;
